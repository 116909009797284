@tailwind base;
@import 'tailwind-preflight.css'; 

@layer base {
  .h0 {
    @apply font-headline text-3xl font-bold md:text-5xl text-neutral-900 dark:text-neutral-50;
  }

  h1,
  .h1 {
    @apply font-headline text-2xl font-bold md:text-4xl text-neutral-900 dark:text-neutral-50;
  }

  h2,
  .h2 {
    @apply font-headline text-xl font-bold md:text-3xl text-neutral-900 dark:text-neutral-50;
  }

  h3,
  .h3 {
    @apply font-headline text-lg font-bold md:text-2xl text-neutral-900 dark:text-neutral-50;
  }

  h4,
  .h4 {
    @apply font-headline text-base font-bold md:text-lg text-neutral-900 dark:text-neutral-50;
  }

  html {
    @apply bg-neutral-50 dark:bg-neutral-default;
  }

  body,
  .p {
    @apply font-body text-sm md:text-base text-neutral-900 dark:text-neutral-50;
  }

  body {
    @apply bg-neutral-50 dark:bg-neutral-default;
  }
}

@tailwind components;
@tailwind utilities;

body {
}
